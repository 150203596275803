/**
 * An Alpine.js custom directive for creating a reusable modal component.
 *
 * When applied to an element using the `x-modal` attribute, it creates a modal
 * that can render HTMX partials and handle modal-related events.
 *
 * Handles:
 *
 * - Dynamic content loading using htmx.
 * - Modal opening and closing, tab navigation, and unsaved changes warnings.
 * - Closing the modal after a successful form submission.
 * - Modal size customization through modifiers.
 * - URL persistence for opened modals.
 *
 * @param {HTMLElement} el - The DOM element the directive is added to.
 * @param {string} options.value - Values passed to the directive.
 * @param {string[]} options.modifiers - Alpine.js configuration modifiers
 * @param {string} options.expression - Describes the directive's behavior.
 * @param {Function} alpine.cleanup - Runs when the directive is removed.
 *
 * @example
 * <button x-modal="{% url '__url__' %}">Open Modal</button>
 */
export default (el, { value, modifiers, expression }, { cleanup }) => {

  // Generate CSS classes based on provided modifiers
  const sizes = ['tall', 'wide', 'full']
  const classes = modifiers
    .filter((m) => sizes.includes(m))
    .map((m) => `modal--${m}`)
    .join(' ')

  // Get the modal root element - the modal container
  const root = document.getElementById('modal')

  // Define the open function
  const open = () => {
    let changed = false // Flag if form content has changed
    let is_preview = false // Flag if preview

    // 1. Create the modal HTML element
    // - Expression is the hx-get URL pointing to the modal content
    // - Classes are derived from modifier arguments
    const modal = htmlToElement(
      modal_t.replace('__hx_get__', expression).replace('__class__', classes)
    )

    // 2. Insert the modal into the DOM
    // TODO: decide how to handle persistance logic for tabs (i.e. widgets)
    // for now, this is a constraint to avoid duplicate modals
    root.replaceChildren(modal)

    // 3. Register htmx attributes on the modal element
    // - Enables htmx behavior for content added to the DOM outside of
    //   the normal htmx request cycle
    htmx.process(modal)

    // 4. Handle clicks on modal overlay or `x` button to close modal
    modal?.addEventListener('click', (event) => {
      if (
        event.target === event.currentTarget ||
        event.target.closest('button')?.classList?.contains('modal__close')
      ) {
        // If there are unsaved changes, show warning
        if (changed) {
          showUnsavedChangesWarning(modal, () => {
            // Proceed with closing modal if user clicks "Close Anyway"
            closeModalAndUpdateUrl(modal)
          })
        // If there are no unsaved changes, close modal
        } else {
          closeModalAndUpdateUrl(modal)
        }
      }
    })

    // 5. Prevent modal from closing for certain user interactions
    // - Set is_preview to true for widget kind changes
    // - Set is_preview to true for tab changes
    // - Show warning if there are unsaved changes
    modal?.addEventListener("htmx:beforeRequest", function (event) {
      const { requestConfig, pathInfo } = event.detail
      const target = event.target as HTMLElement

      // Set is_preview to true for widget kind changes
      if (target.id === "widget-kind-select") {
        is_preview = true
      }

      if (
        pathInfo.requestPath.split("?")[0] === expression.split("?")[0] &&
        (requestConfig.verb === "get" || target.classList.contains("tabbar__link"))
      ) {
        // Set is_preview to true for tab changes
        if (target.classList.contains("tabbar__link")) {
          is_preview = true
        }
        // If there are unsaved changes, show warning
        if (changed) {
          event.preventDefault()
          showUnsavedChangesWarning(modal, () => {
            // Proceed with the tab change if user clicks "Close Anyway"
            changed = false
            event.target.click()
          })
        }
      }
    })

    // 6. Toggle `changed` flag on form changes
    modal.addEventListener('change', (event) => {
      // ignore fields without name (e.g. search in input node)
      if (event.target.name !== '') changed = true
    })

    // 7. Close modal after a successful POST request to the x-modal URL
    modal.addEventListener('htmx:afterRequest', (event) => {
      const { requestConfig, xhr } = event.detail

      // If the request was successful
      if (
        [200, 201].includes(xhr.status) &&
        requestConfig.path.split('?')[0] === expression.split('?')[0] &&
        requestConfig.verb === 'post'
      ) {
        // Reset the changed flag
        changed = false
        // Close the modal if the "Save & Close" button was clicked
        if (!is_preview) {
          closeModalAndUpdateUrl(modal)
          // Reload the page if "reload" modifier is present
          if (modifiers.includes('reload')) {
            location.reload()
          }
        }
      }
    })

    // 8. On form submission set is_preview flag for preview requests
    modal.addEventListener('submit', (event) => {
      is_preview = event.submitter.value === 'Save & Preview' ||
                   event.submitter.value === 'Continue' ||
                   event.submitter.value === 'Query'
    })

    // 9. (Persistence) Update URL with modal id
    if (modifiers.includes('persist')) {
      const params = new URLSearchParams(location.search)
      params.set('modal_item', parseModalId(expression))
      history.replaceState({}, '', `${location.pathname}?${params.toString()}`)
    }
  } // End of open function

  // (Persistence) Open modal if URL contains modal id
  if (modifiers.includes('persist')) {
    const params = new URLSearchParams(location.search)
    if (
      parseModalId(expression) == parseInt(params.get('modal_item')) &&
      // check that the modal is not already open
      !root.hasChildNodes()
    ) {
      open()
    }
  }

  // Open modal on button click
  el.addEventListener(value || 'click', open)

  // Cleanup function used when the directive is removed
  cleanup(() => {
    // TODO: remove event listener, if necessary
    el.removeEventListener('click', open)
  })
}

/* Templates ---------------------------------------------------------- */

/* Modal template */
const modal_t = /*html*/ `<div class="modal __class__">
  <div class="card card--none card--modal">
    <div class="overflow-hidden flex-1"
      hx-get="__hx_get__"
      hx-trigger="load"
      hx-target="this"
    >
      <div class="placeholder-scr placeholder-scr--fillscreen">
        <i class="placeholder-scr__icon fad fa-spinner-third fa-spin fa-2x"></i>
      </div>
    </div>
  </div>
</div>`

/* Warning template */
const warning_t = /*html*/ `<div class="modal flex items-center justify-center">
  <div class="card card--sm card--inline flex flex-col">
    <h3>Be careful!</h3>
    <p class="mb-7">You have unsaved changes that will be lost on closing!</p>
    <div class="flex flex-row gap-7">
      <button class="button button--success button--sm flex-1" @click="$dispatch('modal:stay')">
        Stay
      </button>
      <button class="button button--danger button--outline button--sm flex-1" @click="$dispatch('modal:close')">
        Close Anyway
      </button>
    </div>
  </div>
</div>`

/* Helper functions --------------------------------------------------- */

/**
 * Converts an HTML string to a DOM element.
 *
 * @param {string} html - The HTML string to convert to a DOM element.
 * @returns {HTMLElement} - The converted DOM element.
 *
 * @example
 * const element = htmlToElement('<div class="modal">Modal content</div>');
 * document.body.appendChild(element);
 */
const htmlToElement = (html) => {
  const template = document.createElement('template')
  template.innerHTML = html.trim()
  return template.content.firstChild as HTMLElement
}

/**
 * Extracts the last numeric ID from a given URL string.
 *
 * @param {string} url - The URL string to parse for an ID.
 * @returns {number|null} - The ID or null if not found.
 *
 * @example
 * const id = parseModalId('/projects/5/dashboards/10/widgets/15');
 */
function parseModalId(url) {
  const numbers = url.match(/\d+/g)

  if (numbers && numbers.length > 0) {
    const lastNumber = numbers[numbers.length - 1]
    return parseInt(lastNumber, 10)
  }

  return null
}

/**
 * Closes the modal and updates the URL.
 *
 * Ensures consistent behavior when closing modals by keeping the URL in sync
 * with the UI.
 *
 * @param {HTMLElement} modal - The modal element to be closed.
 *
 * @example
 * const modalElement = document.querySelector('.modal');
 * closeModalAndUpdateUrl(modalElement);
 */
function closeModalAndUpdateUrl(modal: HTMLElement) {
  // TODO: decide whether to implement autosave option for widgets and controls
  // Check for form with hx-post=expression and request submit
  modal.remove()

  const params = new URLSearchParams(location.search)
  params.delete('modal_item')
  history.replaceState(
    {},
    '',
    `${location.pathname}?${params.toString()}`
  )
}

/**
 * Displays a warning modal for unsaved changes and handles user response.
 *
 * Allows the user to choose between staying on the current view or
 * proceeding with their action (closing the modal or changing tabs).

 * @param {HTMLElement} modal - The current modal element.
 * @param {Function} closeAnywayAction - A callback function executed if
 *                                       the user chooses to proceed
 *                                       despite unsaved changes.
 *
 * @example
 * showUnsavedChangesWarning(modalElement, () => {
 *   closeModalAndUpdateUrl(modalElement);
 * });
 */
function showUnsavedChangesWarning(
  modal: HTMLElement,
  closeAnywayAction: () => void
) {
  const warning = htmlToElement(warning_t);

  warning.addEventListener('modal:close', () => {
    warning.remove();
    closeAnywayAction();
  });
  warning.addEventListener('modal:stay', () => {
    warning.remove();
  });

  modal.insertAdjacentElement('afterend', warning);
}