/**
 * Htmx event logging
 */
// function logHtmxEvent(eventName: string, detail: any) {
//   const triggerElement = detail.elt;
//   const targetElement = detail.target;

//   console.log(`HTMX ${eventName} - element id ${triggerElement.id}:`, {
//     trigger: {
//       id: triggerElement.id || 'No ID',
//       tagName: triggerElement.tagName,
//       classes: triggerElement.className,
//       widgetId: triggerElement.closest('gy-widget')?.id || 'Not in widget',
//       hxGet: triggerElement.getAttribute('hx-get') || 'No hx-get',
//       hxTarget: triggerElement.getAttribute('hx-target') || 'No hx-target'
//     },
//     target: targetElement ? {
//       id: targetElement.id || 'No ID',
//       tagName: targetElement.tagName,
//       classes: targetElement.className,
//       widgetId: targetElement.closest('gy-widget')?.id || 'Not in widget'
//     } : 'No target',
//     url: detail.url,
//     method: detail.method,
//     status: detail.xhr?.status,
//     requestId: detail.requestId || 'No request ID',
//     responseText: detail.xhr?.responseText ? detail.xhr.responseText.substring(0, 100) + '...' : 'No response text'
//   });
// }

// // Htmx event listeners in sequential order
// document.addEventListener('htmx:beforeRequest', (event) => logHtmxEvent('beforeRequest', event.detail));
// document.addEventListener('htmx:beforeSend', (event) => logHtmxEvent('beforeSend', event.detail));
// document.addEventListener('htmx:sendError', (event) => logHtmxEvent('sendError', event.detail));
// document.addEventListener('htmx:afterRequest', (event) => logHtmxEvent('afterRequest', event.detail));
// document.addEventListener('htmx:beforeSwap', (event) => logHtmxEvent('beforeSwap', event.detail));
// document.addEventListener('htmx:afterSwap', (event) => logHtmxEvent('afterSwap', event.detail));
// document.addEventListener('htmx:swapError', (event) => logHtmxEvent('swapError', event.detail));
// document.addEventListener('htmx:beforeSettle', (event) => logHtmxEvent('beforeSettle', event.detail));
// document.addEventListener('htmx:afterSettle', (event) => logHtmxEvent('afterSettle', event.detail));
// document.addEventListener('htmx:load', (event) => logHtmxEvent('load', event.detail));
// document.addEventListener('htmx:responseError', (event) => logHtmxEvent('responseError', event.detail));
// document.addEventListener('htmx:configRequest', (event) => logHtmxEvent('configRequest', event.detail));
// document.addEventListener('htmx:historyCacheError', (event) => logHtmxEvent('historyCacheError', event.detail));
// document.addEventListener('htmx:beforeHistorySave', (event) => logHtmxEvent('beforeHistorySave', event.detail));
// document.addEventListener('htmx:beforeHistoryUpdate', (event) => logHtmxEvent('beforeHistoryUpdate', event.detail));
// document.addEventListener('htmx:afterHistoryUpdate', (event) => logHtmxEvent('afterHistoryUpdate', event.detail));
// document.addEventListener('htmx:abort', (event) => logHtmxEvent('abort', event.detail));
// document.addEventListener('htmx:timeout', (event) => logHtmxEvent('timeout', event.detail));

// Support HTMX swap for 400/422 for invalid form submissions
// https://gist.github.com/lysender/a36143c002a84ed2c166bf7567b1a913
document.addEventListener('htmx:beforeSwap', function (evt) {
  if (evt.detail.xhr.status === 422 || evt.detail.xhr.status === 400) {
    evt.detail.shouldSwap = true
    evt.detail.isError = false
  }
})

/**
 * Event listener for widgets' "duplicate" button in the dashboard canvas.
 * Finds the relevant form and resets its submit button for HTMX events.
 */
document.addEventListener('htmx:afterRequest', (event) => {
  const form = event.detail.elt.closest('form.form--no-layout');
  if (form) {
    const button = form.querySelector(
      'button.list__item.list__item--interactive'
    );
    if (button) {
      resetSubmitButton(button);
    }
  }
});

/**
 * Resets a submit button to its original state.
 * Removes the loading indicator added by the "submit" event listener
 * located in apps/base/javascript/submit.ts.
 */
function resetSubmitButton(button: HTMLElement) {
  const loadingIndicator = button.querySelector('.placeholder-scr--inline');
  if (loadingIndicator) {
    loadingIndicator.remove();
  }
  button.style.position = '';
  button.style.color = '';
}


export {}
